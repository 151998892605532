import cyprusData from "./cyprus"
import londonData from "./london"

const DATA = JSON.parse(JSON.stringify(cyprusData))

cyprusData.forEach((cEl, cIndex) => {
  londonData.forEach((lEl, lIndex) => {
    if (cEl.name === lEl.name) {
      for (const items of londonData[lIndex].points) {
        DATA[cIndex].points.push(items)
      }
    }
  })
})

export default DATA
