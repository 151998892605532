const DATA = [
  {
    name: "Macroeconomic overviews",
    points: [
      {
        title: "Fiona Mullen, Sapienta Economics (April 2023)",
        url: "/pdf/SapientaCountryAnalysisCyprus-2023-04.pdf",
      },
      {
        title: "Fiona Mullen, Sapienta Economics (March 2023)",
        url: "/pdf/SapientaCountryAnalysisCyprus-2023-03.pdf",
      },
      {
        title: "Fiona Mullen, Sapienta Economics (February 2023)",
        url: "/pdf/SapientaCountryAnalysisCyprus-2023-02.pdf",
      },
      {
        title: "Macro Trends Q2 2023 by Gregory Klumov",
        url: "/pdf/MACRO-TRENDS-Q2-2023.pdf",
      },
      {
        title: "Global Economic Recovery Endures but the Road Is Getting Rocky",
        desc: "The IMF’s April forecast.",
        url: "https://www.imf.org/en/Blogs/Articles/2023/04/11/global-economic-recovery-endures-but-the-road-is-getting-rocky",
      },
      {
        title: "A Perilous Macroeconomic Moment",
        desc: "Eswar Prasad, Professor of Economics in the Dyson School at Cornell University and senior fellow at the Brookings Institution. A look at why central banks will have to maintain tight monetary conditions even as business and consumer confidence is likely to remain subdued in much of the world, constraining global growth. Peterson Institute for International Economics. Karen Dynan, Senior Research Staff.",
        url: "https://www.project-syndicate.org/commentary/high-inflation-tightening-financial-conditions-limit-medium-term-growth-by-eswar-prasad-2023-04",
      },
      {
        title:
          "Unemployment Is Low. Inflation Is Falling. But What Comes Next?",
        desc: "Ben Casselmen at the New York Times. Despite hopeful signs, economists worry that a recession is on the way or that the Federal Reserve will cause one in trying to rein in inflation.",
        url: "https://www.nytimes.com/2023/04/14/business/economy/economy-jobs-inflation-recession.html?name=styln-us-economy&region=TOP_BANNER&block=storyline_menu_recirc&action=click&pgtype=Article&variant=undefined",
      },
      {
        title:
          "Fiscal crisis nears as McCarthy takes debt ceiling plan to Wall Street",
        desc: "Tony Romm, The Washington Post. The House speaker outlined the party’s new proposal at the New York Stock Exchange, more than a decade after a similar GOP-led standoff hammered U.S. markets",
        url: "https://www.washingtonpost.com/business/2023/04/17/debt-ceiling-crisis-kevin-mccarthy/",
      },
      {
        title: "How to explain the puzzle of the world economy",
        desc: "<i>The Economist</i>. Discusses the difficulty of forecasting and understanding the wider picture",
        url: "https://www.economist.com/finance-and-economics/2023/04/17/how-to-explain-the-puzzle-of-the-world-economy",
      },
      {
        title: "Workforce ecosystems and AI",
        desc: "From the Brookings Institute. David Kiron, Editorial Director, MIT Sloan Management Review, Elizabeth Altman, Associate Professor of Management - Manning School of Business, University of Massachusetts Lowell,and Christoph Riedl, Associate Professor - Northeastern University, D'Amore-McKim School of Business.",
        url: "https://www.brookings.edu/research/workforce-ecosystems-and-ai/",
      },
    ],
  },
  {
    name: "Stocks and ETFs",
    points: [
      {
        title:
          "Case for Stocks Is Seen in Model Showing Economic Bottom Is Past",
        desc: "ByJess Menton and Elena Popina, Bloomberg.com. Examines why US stocks are continuing to rally.",
        url: "https://www.bloomberg.com/news/articles/2023-04-16/case-for-stocks-is-seen-in-model-showing-economic-bottom-is-past?srnd=premium-europe",
      },
      {
        title:
          "Second-Quarter 2023 Stock Market Outlook: Undervalued, but a Rough Road Ahead",
        desc: "Dave Sekera, CFA, Morningstar. Reviews the impact of tighter monetary policy and credit tightening on growth and valuations.",
        url: "https://www.morningstar.com/articles/1148099/second-quarter-2023-stock-market-outlook-undervalued-but-a-rough-road-ahead",
      },
      {
        title: "Flows into ETFs treble in March as investors seek safety",
        desc: "Steve Johson, <i>The Financial Times</i>. Examines where and why ETFs were moving the end of Q1.",
        url: "https://www.ft.com/content/405d13f2-b2f9-44ee-b4b8-d3f26f5d46bb",
      },
    ],
  },
  {
    name: "Bonds",
    points: [
      {
        title: "Credit Suisse’s Risky-Bond Wipeout Hurts Asia’s Rich",
        desc: "Rebecca Feng, The Wall Street Journal. This examines the impact of the write down of Credit Suisse’s bonds.",
        url: "https://www.wsj.com/articles/credit-suisses-risky-bond-wipeout-hurts-asias-rich-a502ce",
      },
      {
        title: "Banking turmoil creates uncertain outlook for bond yields",
        desc: "Chris Flood, The Financial Times. Discusses where on the yield curve provides the most attractive risk-reward balance",
        url: "https://www.ft.com/content/fa785a86-dfc8-411e-b1f6-14d64241e4a7",
      },
    ],
  },
  {
    name: "Alternatives",
    points: [
      {
        title:
          "McKinsey Global Private Markets Review: Private Markets Turn Down the Volume",
        desc: "A review by consultancy McKinsey of what happened in 2022 and why and what it means moving forward.",
        url: "https://www.mckinsey.com/industries/private-equity-and-principal-investors/our-insights/mckinseys-private-markets-annual-review?gclid=CjwKCAjw3POhBhBQEiwAqTCuBqo2vLp4HiQ8m9cVof8xZCNmQWBMsndyYNnjKA_k5oNTPAabCKKb0hoCoIYQAvD_BwE&gclsrc=aw.ds",
      },
      {
        title: "Private Equity Outlook in 2023: Anatomy of a Slowdown",
        desc: "A report by consultancy Bain & Company examining the macroenvironment and its impact on dealmaking in 2023.",
        url: "https://www.bain.com/insights/private-equity-outlook-global-private-equity-report-2023/",
      },
    ],
  },
  {
    name: "Currencies",
    points: [
      {
        title: "A BRICS Threat to the Dollar?",
        desc: "Jim O’Neil, former chairman of Goldman Sachs Asset Management and a former UK treasury minister.  Examines whether BRIC countries plus Saudi Arabia and China can cooperate enough to pose a strategic challenge to the US dollar.",
        url: "https://www.project-syndicate.org/commentary/brics-plus-and-the-future-of-dollar-dominance-by-jim-o-neill-2023-04",
      },
      {
        title:
          "The changing nexus between commodity prices and the dollar: causes and implications",
        desc: "Bank for International Settlements (BIS). Boris Hoffman, Deniz Igan, and Daniel Rees, This notes that the USD and commodity prices have been moving in tandem, but these are only temporary as any change would be overly challenging for commodity importing economies’ financial frameworks.",
        url: "https://www.bis.org/publ/bisbull74.htm",
      },
      {
        title:
          "Silicon Valley Bank and the double-edged sword of digital efficiency",
        desc: "Julian Jacobs, Senior Economist at OMFIF considers the impact of digital finance on payments and currency flows.",
        url: "https://www.omfif.org/2023/04/silicon-valley-bank-and-the-double-edged-sword-of-digital-efficiency/",
      },
    ],
  },
  {
    name: "Emerging Markets",
    points: [
      {
        title: "Are Emerging Markets Back on Investors Radars?",
        desc: "Samantha Barnes at Banker.com examines why EMs, particularly in Latin America, will have a better 2023.",
        url: "https://internationalbanker.com/brokerage/are-emerging-markets-back-on-investors-radars/",
      },
      {
        title:
          "Economic Outlook Emerging Markets Q2 2023: Global Crosscurrents Make For A Bumpy Deceleration",
        desc: "An update by global credit rating agency S&P. ",
        url: "https://www.spglobal.com/ratings/en/research/articles/230327-economic-outlook-emerging-markets-q2-2023-global-crosscurrents-make-for-a-bumpy-deceleration-12676292",
      },
      {
        title: "Asia can withstand destabilisation of western tightening",
        desc: "Taimur Baig, Managing Director and Chief Economist, DBS Group Research for OMFIF. This examines the risks of QT in Western economies but says that Asian banks have lower risks of unrealised losses and that Asian economies are on a strong footing.",
        url: "https://www.omfif.org/2023/04/asia-can-withstand-destabilisation-of-western-tightening/",
      },
    ],
  },
  {
    name: "Commodities",
    points: [
      {
        title: "Europe’s New Energy Map",
        desc: "Edoardo Campanella, Senior Fellow at the Mossavar-Rahmani Center for Business and Government at the Harvard Kennedy School, takes a look who is benefiting from the redrawing of oil and gas away from Russia and the implications for Asia and the Middle East.",
        url: "https://www.project-syndicate.org/commentary/europe-new-energy-map-middle-east-replacing-russia-by-edoardo-campanella-2023-04?barrier=accesspaylog",
      },
      {
        title:
          "Will the scramble for rare earths produce a transatlantic trade accord?",
        desc: "Cecilia Malmström, Peterson Institute for International Economics. This examines the EC’s rare earth metal strategyand whether a trade agreement can be made between Europe and the US.",
        url: "https://www.piie.com/blogs/realtime-economics/will-scramble-rare-earths-produce-transatlantic-trade-accord",
      },
      {
        title: "Short Term Energy Outlook April 2023",
        desc: "From the US Energy Information Agency (EIA)",
        url: "https://www.eia.gov/outlooks/steo/pdf/steo_full.pdf",
      },
      {
        title: "‘Net Zero’ Will Mean a Mining Boom",
        desc: "Daniel Yergin, author, speaker, energy expert, and economic historian. Yergin is vice chairman of S&P Global. The Wall Street Journal.",
        url: "https://www.wsj.com/articles/net-zero-will-mean-a-mining-boom-electric-cars-minerals-oil-fossil-fuels-climate-change-policy-cb8d5137?mod=opinion_lead_pos5",
      },
      {
        title: "Oil Market Report - March 2023",
        desc: "The IEA (International Energy Association) latest oil market report (March 2023)",
        url: "https://www.iea.org/reports/oil-market-report-march-2023",
      },
    ],
  },
  {
    name: "Cryptocurrencies and digital ecosystem&nbsp;developments",
    points: [
      {
        title: "Smart regulation needed to rebuild trust in crypto",
        desc: "Rana Kortam, Director, Global Public Policy at Binance for OMFIF.  This considers what a digital assets regulatory framework may look like from a practitioner’s view",
        url: "https://www.omfif.org/2023/03/smart-regulation-needed-to-rebuild-trust-in-crypto/",
      },
      {
        title:
          "Blockchain-based financial applications could help increase the competitiveness of the City",
        desc: "This article considers how blockchain can increase financial competitiveness and help embed the most complex business logic enhancing trading possibilities.",
        url: "https://blogs.lse.ac.uk/businessreview/2022/10/04/blockchain-based-financial-applications-could-help-increase-the-competitiveness-of-the-city/",
      },
      {
        title: "The Real-World Costs of the Digital Race for Bitcoin",
        desc: "Gabriel J.X. Dance,The New York Times. Interviews with Bitcoin miners, energy experts, scientists and politicians in Texas and North Dakota.  ",
        url: "https://www.nytimes.com/2023/04/09/business/bitcoin-mining-electricity-pollution.html",
      },
    ],
  },
]

export default DATA
