import styles from "./Col.module.scss"
import { useMemo } from "react"

export default function Col(props) {
  const colStyles = {}

  const vars = useMemo(() => {
    Object.keys(props).forEach((key) => {
      if (props[key]) {
        colStyles[`--cols-item${key !== "cols" ? `-${key}` : ""}`] = props[key]
      }
    })
    return colStyles
  }, [colStyles])

  return (
    <div
      className={`${styles.col} ${props.noGutters ? styles.nogutters : ""} ${
        props.className ? props.className : ""
      }`}
      style={vars}
    >
      {props.children}
    </div>
  )
}
