"use client"

import Container from "../Container/Container"
import styles from "./ReadingRoom.module.scss"
// import pStyles from "./Pagination.module.scss"
import Item from "./Item"
import Menu from "./Menu"
import { useEffect, useContext } from "react"
import { CurrentReadingIndexContext } from "@/contexts/CurrentReadingIndexContext"
import { useRouter, usePathname, useSearchParams } from "next/navigation"
import { useTranslations } from "next-intl"
import ROOMDATA from "@/data/readingRoom"

import { updateQueryParams, setQueryActive } from "@/utils/readingroom"

export default function ReadingRoomBlock({
  title,
  hasDesc,
  isPage = false,
  mobileMenu = false,
  isGlobal = false,
  isMainPage = false,
  isEvent = false,
  items = ROOMDATA.global.readingRoom,
}) {
  const t = useTranslations()
  const { currentIndex, setCurrentIndex } = useContext(
    CurrentReadingIndexContext
  )

  const router = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const routerItem = searchParams.get("item")

  if (isGlobal && routerItem) {
    items.forEach((el, index) => {
      if (
        el.name.toLowerCase().replace("&nbsp;", " ").split(" ").join("-") ===
        routerItem
      ) {
        setCurrentIndex(index)
      }
    })
  }

  const setItemActive = (newIndex) => {
    const newItem = isEvent
      ? items.filter((el, index) => index === newIndex).title
      : setQueryActive(items, newIndex)

    if (isMainPage) {
      setCurrentIndex(newIndex)

      updateQueryParams({ item: newItem }, pathname, router, isMainPage)
    } else {
      if (isGlobal) {
        updateQueryParams({ item: newItem }, pathname, router)
      }

      setCurrentIndex(newIndex)
    }

    // if (newIndex === currentIndex && notDesktop.matches) {
    //   setCurrentIndex(-10)
    // } else {
    //   setCurrentIndex(newIndex)
    // }
  }

  function setCIndex() {
    const notDesktop = isPage
      ? window.matchMedia("(max-width: 959px)")
      : window.matchMedia("(max-width: 1279px)")
    if (notDesktop.matches && isPage) {
      setCurrentIndex(currentIndex)
    } else if (isEvent) {
      setCurrentIndex(currentIndex)
    } else {
      setCurrentIndex(0)
    }
  }

  useEffect(() => {
    setCIndex()
    window.addEventListener("resize", setCIndex)

    console.log("currentIndex", currentIndex)

    return () => {
      window.removeEventListener("resize", setCIndex)
    }
  }, [])

  const filteredItems = isEvent
    ? items.filter((el) => el.items.length > 0)
    : items

  const Items =
    filteredItems &&
    filteredItems.map((el, index) => {
      return (
        <Item
          item={el}
          key={index}
          index={index}
          currentIndex={currentIndex}
          setItemActive={setItemActive}
          isPage={isPage}
          isEvent={isEvent}
        />
      )
    })

  return (
    <section
      className={`${styles.room} ${
        isPage ? styles.room_page : styles.room_block
      } ${isEvent ? styles.room_event : ""} ${
        isMainPage ? styles.room_mainpage : ""
      }`}
      id="readingRoom"
    >
      {!isPage && (
        <div className={styles.room__head}>
          <Container>
            <h2 className={styles.room__title}>
              {title || t("readingRoom.title")}
              {/* {isEvent && (
                <a
                  href="/reading-room"
                  dangerouslySetInnerHTML={{
                    __html: t("readingRoom.viewMore"),
                  }}
                ></a>
              )} */}
            </h2>
            {hasDesc && (
              <p
                className={styles.room__desc}
                dangerouslySetInnerHTML={{
                  __html: t.raw("readingRoom.desc"),
                }}
              ></p>
            )}
          </Container>
        </div>
      )}
      <div className={styles.room__items}>
        {mobileMenu && <Menu items={items} />}
        <Container
          className={`${styles.room__container} ${
            isPage ? styles.room__container_page : ""
          }`}
        >
          {filteredItems && (
            <ul
              className={`${styles.room__names} ${
                isPage ? styles.room__names_page : ""
              } ${isMainPage ? styles.room__names_mainpage : ""}`}
            >
              {filteredItems.map((el, index) => {
                return (
                  <li
                    id={index}
                    key={index}
                    className={`${styles.room__name} ${
                      styles.room__name_desktop
                    } ${index === currentIndex && styles.room__name_active}`}
                    onClick={() => setItemActive(index)}
                  >
                    <strong
                      dangerouslySetInnerHTML={{
                        __html: isEvent ? el.title : el.name,
                      }}
                    ></strong>
                    <span className={styles.room__highlighter}></span>
                  </li>
                )
              })}
            </ul>
          )}

          {!isMainPage && (
            <ul
              className={`${styles.room__list} ${
                mobileMenu ? styles.room__list_mobilemenu : ""
              }`}
            >
              {Items}
            </ul>
          )}
        </Container>
      </div>
    </section>
  )
}
