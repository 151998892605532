export const mergeUrl = (
  env = process.env.NEXT_PUBLIC_ENV,
  endpoint = "/leads/"
) => {
  let url = `https://mareg.exante.eu/api/v1${endpoint}` // TODO: stage url
  if (env !== "production" && env !== "prod") {
    url = `https://mareg-stage.exante.eu/api/v1${endpoint}`
  }
  return url
}

export const getFormId = () => {
  return process.env.NEXT_PUBLIC_FORM_ID
}
