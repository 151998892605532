const DATA = [
  {
    name: "Macroeconomic overviews",
    points: [
      {
        title: "Secular Stagnation, Not Secular Stagflation",
        desc: "Willem Buiter, former chief economist at Citibank and former member of the Monetary Policy Committee of the Bank of England. He considers  stagflation vs secular stagnation and what central banks can do.",
        url: "https://www.project-syndicate.org/commentary/secular-stagnation-not-secular-stagflation-by-willem-h-buiter-2023-01",
      },
      {
        title: "Global Economic Prospects — January 2023",
        desc: "The World Bank’s latest view on growth in 2023. Here is the forward from the World Bank President.",
        url: "https://openknowledge.worldbank.org/bitstream/handle/10986/38030/GEP-January-2023-Foreword.pdf",
      },
      {
        title: "The Looming Financial Contagion",
        desc: "Kenneth Rogoff, Professor of Economics and Public Policy at Harvard University and former chief economist of the International Monetary Fund, considers where financial contagion risks may be found and why.",
        url: "https://www.project-syndicate.org/commentary/looming-financial-crisis-2023-rising-interest-rates-by-kenneth-rogoff-2023-01",
      },
      {
        title: "Outlook 2023: It’s not every day that Europe gets lucky",
        desc: "OMFIF. Agnès Belaisch, Chief European Strategist, Barings Investment Institute, focuses on the likelihood and depth of recession prospects in Europe.",
        url: "https://www.omfif.org/2023/01/outlook-2023-its-not-every-day-that-europe-gets-lucky/",
      },
    ],
  },
  {
    name: "Stocks and ETFs",
    points: [
      {
        title: "A Better Year for Stock Markets?",
        desc: "Jim O’Neill, former chairman of Goldman Sachs Asset Management and a former UK treasury minister, suggests that the market pessimists might be wrong this year.",
        url: "https://www.project-syndicate.org/commentary/markets-2023-five-day-rule-easing-inflation-by-jim-o-neill-2023-01",
      },
      {
        title: "ETF industry storms through 2022’s headwinds",
        desc: "Financial Times, review of ETF prospects.",
        url: "https://www.ft.com/content/81c4105e-1c3d-4728-8223-204e4ecd5721",
      },
    ],
  },
  {
    name: "Bonds",
    points: [
      {
        title: "If it doesn’t trade, is it really marketable debt?",
        desc: "Rebecca Christie, a non-resident fellow at the Brussels-based Bruegel think tank and Brussels columnist for Reuters Breakingviews, provides a look at European sovereign debt.",
        url: "https://www.bruegel.org/blog-post/if-it-doesnt-trade-it-really-marketable-debt",
      },
      {
        title: "Higher Yields, Headwinds for Stocks Make 2023 Year of the Bond",
        desc: "Bloomberg explains why it thinks corporate bonds will perform well in 2023.",
        url: "https://www.bloomberg.com/news/articles/2023-01-11/corporate-bonds-poised-for-2023-bounce-amid-recession-fears",
      },
      {
        title: "Finally, Good News: Why Bonds Should Do Well In 2023",
        desc: "Larry Light, a financial journalist for The Wall Street Journal, Business Week, and Forbes. An opinion piece on what will push bond performance in 2023. ",
        url: "https://www.forbes.com/sites/lawrencelight/2022/12/28/finally-good-news-why-bonds-should-do-well-in-2023/?sh=45fc965262d0",
      },
    ],
  },
  {
    name: "Alternatives",
    points: [
      {
        title:
          "Private markets are more likely to deflate than implode in 2023",
        desc: "Financial Times. “Private markets are more likely to deflate than implode in 2023”. A look at what may happen in private markets in 2023.",
        url: "https://www.ft.com/content/2b04ccec-035c-4d66-871e-7ecca4e45b2c",
      },
      {
        title: "Private equity slowdown a chance for innovation",
        desc: "PwC Private Equity Outlook for 2023.",
        url: "https://www.pwc.com/us/en/industries/financial-services/library/private-equity-deals-outlook.html",
      },
    ],
  },
  {
    name: "Currencies",
    points: [
      {
        title: "How Countries Should Respond to the Strong Dollar",
        desc: "Gita Gopinath and Pierre-Olivier Gourinchas of the IMF give their opinions on what is driving US dollar growth and what countries can do.",
        url: "https://www.imf.org/en/Blogs/Articles/2022/10/14/how-countries-should-respond-to-the-strong-dollar",
      },
      {
        title:
          "Outlook 2023: Foreign exchange traders might actually have to earn their keep",
        desc: "Mark Sobel, US Chair of OMFIF, reviews the factors that may cause the USD to decline.",
        url: "https://www.omfif.org/2023/01/outlook-2023-foreign-exchange-traders-might-actually-have-to-earn-their-keep/",
      },
    ],
  },
  {
    name: "Emerging Markets",
    points: [
      {
        title: "Russia/Ukraine and the World: Fog of War?",
        desc: "Sir Adam Thomson, former UK Ambassador to NATO, the UN, Pakistan and former senior UK rep to Moscow, Washington, New Delhi; Meral Karasulu, Invesco Director of FI Research; and Arnab Das, Invesco Global Macro Strategist,collaborated to pull together this deep dive on scenarios, macro and market implications of the Russia/Ukraine crisis. They explore the motivations behind the crisis, how further realignment in the balance of power and geopolitical / geo-economic relations in the world may occur over the longer term, and how developed and emerging markets economies will shift policies in confronting risk.",
        url: "https://media.licdn.com/dms/document/C4E1FAQGDtMW96CMNjA/feedshare-document-pdf-analyzed/0/1644655647706?e=1674691200&v=beta&t=C22vS6xfOZHnWPCSqY2yyZrDv2S6nnGk23Ls4e3b4eU",
      },
      {
        title:
          "Emerging market governments raise $40bn in January borrowing binge",
        desc: "Financial Times, A look at brightening sentiment for Emerging Markets in 2023 ",
        url: "https://www.ft.com/content/ab26195f-dd30-41dc-bea2-21a1c6fe67fe",
      },
      {
        title: "Outlook 2023: Emerging markets are not out of the woods",
        desc: "Nikhil Sanghani, Managing Director, Research, OMFIF, highlights restrictive policies, debt concerns for some countries, and political risks rising due to elections. ",
        url: "https://www.omfif.org/2023/01/outlook-2023-emerging-markets-are-not-out-of-the-woods/",
      },
    ],
  },
  {
    name: "Commodities",
    points: [
      {
        title: "What to watch in commodities in 2023",
        desc: "Economist Intelligence Unit (EIU).",
        url: "https://www.eiu.com/n/what-to-watch-in-commodities-in-2023/",
      },
      {
        title:
          "Commodities 2023: Metals market watchers upbeat on 2023 price, demand prospects after sluggish start",
        desc: "S&P Global’s view on metals.",
        url: "https://www.spglobal.com/commodityinsights/en/market-insights/latest-news/metals/122322-metals-market-watchers-upbeat-on-2023-price-demand-prospects-after-sluggish-start",
      },
      {
        title: "SHORT-TERM ENERGY OUTLOOK",
        desc: "The US Energy Information Administration’s latest outlook for oil markets.",
        url: "https://www.eia.gov/outlooks/steo/",
      },
      {
        title: "Oil Market Report - December 2022",
        desc: "The IEA (International Energy Association) latest oil market report (December 2022).",
        url: "https://www.iea.org/reports/oil-market-report-december-2022",
      },
    ],
  },
  {
    name: "Cryptocurrencies and digital ecosystem&nbsp;developments",
    points: [
      {
        title: "What Is Web3 Good For?",
        desc: "This article considers how web3 (blockchain) technologies could help to resolve the problem of balancing security vs privacy. ",
        url: "https://www.project-syndicate.org/commentary/web3-improve-financial-system-for-privacy-security-and-law-enforcement-by-tomicah-tillemann-2022-09",
      },
      {
        title:
          "Blockchain-based financial applications could help increase the competitiveness of the City",
        desc: "This article considers how blockchain can increase financial competitiveness and help embed the most complex business logic enhancing trading possibilities.",
        url: "https://blogs.lse.ac.uk/businessreview/2022/10/04/blockchain-based-financial-applications-could-help-increase-the-competitiveness-of-the-city/",
      },
      {
        title:
          "Crypto and blockchain industries desperately need a win in 2023",
        desc: "Lewis McLellan, Editor of the Digital Monetary Institute, OMFIF. This article considers the changes that the big crypto players, such as Binance, will need to make in terms of risk management and the return of truly public blockchain models.",
        url: "https://www.omfif.org/2023/01/crypto-and-blockchain-industries-desperately-need-a-win-in-2023/",
      },
    ],
  },
]

export default DATA
