import globalReadingRoom from "./global"
import cyprusReadingRoom from "./cyprus"
import londonReadingRoom from "./london"

const DATA = {
  global: {
    title: "Reading Room",
    desc: "As part of our Horizon events we wish to provide our guests with some background information<br>from&nbsp;a&nbsp;variety of public sources that may be interesting and/or useful reference material.",
    readingRoom: globalReadingRoom,
  },
  events: [
    {
      slug: "cyprus",
      title:
        "What’s on the Horizon for Global markets and how could it impact your portfolio?",
      desc: "Following on from the success of The EXANTE Horizon panel discussion at The London Stock Exchange, the second event in the series will take place on 27 April. Join us as our global panel of financial experts will discuss the key Q1 market movements and consider the investment opportunities and risks facing the markets in 2023 and beyond.",
      details: "27 APR 2023, Cyprus",
      picMini: "/images/reading-room/cyprus-mini.jpg",
      bgImage: "/images/reading-room/london-bg.jpg",
      link: "/events/whats-on-the-horizon-for-global-markets-and-how-could-it-impact-your-portfolio/",
      readingRoom: cyprusReadingRoom,
    },
    {
      slug: "london",
      title:
        "What’s on the horizon for stocks, bonds, commodities & alternative assets in 2023?",
      desc: "Join EXANTE panel discussion as we hear from four leading industry experts.",
      details: "26 Jan 2023, London",
      picMini: "/images/reading-room/london-mini.jpg",
      bgImage: "/images/reading-room/london-bg.jpg",
      link: "/events/whats-horizon-financial-markets-2023/",
      readingRoom: londonReadingRoom,
    },
  ],
}

export default DATA
