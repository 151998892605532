"use client"
import Container from "../Container/Container"
import styles from "./Menu.module.scss"
import { CurrentReadingIndexContext } from "@/contexts/CurrentReadingIndexContext"
import { useState, useContext } from "react"
import { useRouter, useSearchParams } from "next/navigation"
import { updateQueryParams, setQueryActive } from "@/utils/readingroom"

export default function Menu({ items }) {
  const { currentIndex, setCurrentIndex } = useContext(
    CurrentReadingIndexContext
  )

  const [menuOpen, setMenuOpen] = useState(false)

  let activeItem = items.filter((el, index) => {
    return index === currentIndex
  })[0]

  const router = useRouter()
  const searchParams = useSearchParams()
  const routerItem = searchParams.get("item")

  items.forEach((el, index) => {
    if (
      el.name.toLowerCase().replace("&nbsp;", " ").split(" ").join("-") ===
      routerItem
    ) {
      setCurrentIndex(index)
    }
  })

  const setItemActive = (newIndex) => {
    const newItem = setQueryActive(items, newIndex)

    updateQueryParams({ item: newItem }, pathname, router)

    setCurrentIndex(newIndex)

    setMenuOpen(false)
  }

  function toggleMenu() {
    setMenuOpen(!menuOpen)
  }

  return (
    <div className={styles.menu}>
      <div className={styles.menu__top} onClick={toggleMenu}>
        <Container className={styles.menu__topcontainer}>
          <strong
            dangerouslySetInnerHTML={{ __html: activeItem?.name }}
          ></strong>
          <img src="/images/arrow-menu.svg" alt="arrow menu" />
        </Container>
      </div>
      {menuOpen && (
        <div className={styles.menu__items}>
          <Container>
            {items.map((el, index) => {
              return (
                <strong
                  id={el.name
                    .toLowerCase()
                    .replace("&nbsp;", " ")
                    .split(" ")
                    .join("-")}
                  key={index}
                  className={`${styles.menu__item} ${
                    index === currentIndex ? styles.menu__item_current : ""
                  }`}
                  onClick={() => setItemActive(index)}
                  dangerouslySetInnerHTML={{ __html: el.name }}
                ></strong>
              )
            })}
          </Container>
        </div>
      )}
    </div>
  )
}
