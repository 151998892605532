export default function LinkedinIcon() {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4346_883)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.89206 16V5.20427H0.21723V16H3.89206ZM2.05463 3.7303C3.33611 3.7303 4.13374 2.90131 4.13374 1.86531C4.10986 0.805988 3.33612 0 2.07894 0C0.82189 3.13554e-08 0 0.805991 0 1.86531C0 2.90131 0.797454 3.7303 2.03069 3.7303H2.05463Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.13379 16.0002H9.80859V9.97144C9.80859 9.64879 9.83247 9.32645 9.9295 9.09581C10.1952 8.45114 10.7998 7.78348 11.815 7.78348C13.1447 7.78348 13.6767 8.77347 13.6767 10.2248V16.0002H17.3512V9.81011C17.3512 6.49415 15.5382 4.95117 13.1205 4.95117C11.138 4.95117 10.2677 6.03317 9.78414 6.77016H9.80869V5.2045H6.13389C6.18211 6.2175 6.13379 16.0002 6.13379 16.0002Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_4346_883">
          <rect width="17.3511" height="20" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  )
}
