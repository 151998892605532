"use client"
import styles from "./ReadingRoom.module.scss"
import pStyles from "./Pagination.module.scss"
import { useState, useLayoutEffect, useRef, useEffect } from "react"
import { useTranslations } from "next-intl"
import ReactPaginate from "react-paginate"

export default function Item({
  item,
  index,
  currentIndex,
  setItemActive,
  isPage = false,
  isEvent = false,
}) {
  const [longContent, setLongContent] = useState(false)
  const roomPointsRef = useRef()
  const roomPointsContainerRef = useRef()
  let pageScroll = typeof window !== "undefined" ? window.pageYOffset : 0
  let lastScrollTop = 0
  const t = useTranslations()

  useLayoutEffect(() => {
    if (!isPage) {
      if (
        roomPointsContainerRef.current?.clientHeight <
        roomPointsRef.current?.clientHeight
      ) {
        setLongContent(true)
      } else {
        setLongContent(false)
      }
    }
  }, [currentIndex])

  function setItemActiveFunc() {
    setItemActive(index)
  }

  // PAGINATION //

  const itemsPerPage = 10

  const [itemOffset, setItemOffset] = useState(0)

  const paginationItems = item.points
  const endOffset = itemOffset + itemsPerPage

  let currentItems = paginationItems?.slice(itemOffset, endOffset)

  const pageCount = Math.ceil(paginationItems?.length / itemsPerPage)

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % paginationItems.length

    setItemOffset(newOffset)
  }

  // //

  useLayoutEffect(() => {
    roomPointsContainerRef.current.addEventListener("scroll", () => {
      if (
        roomPointsContainerRef.current.clientHeight &&
        roomPointsContainerRef.current.scrollTop &&
        roomPointsRef.current.offsetHeight
      ) {
        setLongContent(
          roomPointsContainerRef.current.clientHeight +
            roomPointsContainerRef.current.scrollTop !==
            roomPointsRef.current.offsetHeight
        )
      }

      if (roomPointsContainerRef.current.scrollTop > lastScrollTop) {
        setLongContent(false)
      } else {
        setLongContent(true)
      }
      lastScrollTop = roomPointsContainerRef.current.scrollTop
    })
  }, [pageScroll])

  return (
    <>
      <li className={styles.room__item}>
        <div
          id={index}
          className={`${styles.room__name} ${
            index === currentIndex ? styles.room__name_active : ""
          }`}
          onClick={setItemActiveFunc}
        >
          <strong
            dangerouslySetInnerHTML={{
              __html: isEvent ? item.title : item.name,
            }}
          ></strong>
          <span className={styles.room__highlighter}></span>
        </div>
        <div
          className={styles.room__pointsContainer}
          ref={roomPointsContainerRef}
        >
          <ul
            ref={roomPointsRef}
            className={`${styles.room__points} ${
              index === currentIndex && styles.room__points_active
            } js-roompoints`}
          >
            {isEvent &&
              item.items.map((point, pIndex) => {
                return (
                  <li className={styles.room__point} key={pIndex}>
                    <a href={point.link} target="_blank" rel="noreferrer">
                      {point.name && (
                        <strong
                          dangerouslySetInnerHTML={{ __html: point.name }}
                        ></strong>
                      )}
                      {point.description && (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: point.description,
                          }}
                        ></p>
                      )}
                    </a>
                  </li>
                )
              })}

            {isPage &&
              currentItems.map((point, pIndex) => {
                return (
                  <li className={styles.room__point} key={pIndex}>
                    <a href={point.url} target="_blank" rel="noreferrer">
                      {point.title && (
                        <strong
                          dangerouslySetInnerHTML={{ __html: point.title }}
                        ></strong>
                      )}
                      <p dangerouslySetInnerHTML={{ __html: point.desc }}></p>
                    </a>
                  </li>
                )
              })}
          </ul>
          {isPage && item.points.length > itemsPerPage && (
            <ReactPaginate
              className={`${pStyles.pagination} ${
                pStyles.pagination_readingroom
              } ${
                index === currentIndex ? pStyles.pagination_active : ""
              } pagination`}
              breakLabel=""
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              nextLabel=""
              previousLabel=""
              renderOnZeroPageCount={null}
              previousLinkClassName={`${pStyles.pagination__arrow} ${pStyles.pagination__arrow_prev}`}
              nextLinkClassName={`${pStyles.pagination__arrow} ${pStyles.pagination__arrow_next}`}
              disabledLinkClassName={pStyles.disabled}
              pageClassName={pStyles.pagination__page}
              activeClassName={pStyles.active}
            />
          )}
        </div>
      </li>

      {!isPage && (
        <div
          style={{ color: "#fff" }}
          className={`${styles.room__showmore} ${
            longContent ? styles.room__showmore_active : ""
          }`}
        >
          <img src="/images/arrow-scroll.svg" />
          <span>{t.raw("readingRoom.scrollDownText")}</span>
        </div>
      )}
    </>
  )
}
