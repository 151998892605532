export const createQueryString = (params) => {
  const urlParams = new URLSearchParams(params)
  return urlParams.toString()
}

export const updateQueryParams = (
  params,
  pathname,
  router,
  isMainPage = false
) => {
  const resultPathName = isMainPage ? "/reading-room/" : pathname
  const url = new URL(window.location.href)
  const urlParams = new URLSearchParams(url.search)

  for (const [key, value] of Object.entries(params)) {
    if (!value) {
      urlParams.delete(key)
      delete params[key]
    }
  }

  router.push(`${resultPathName}?${createQueryString(params)}`)
}

export const setQueryActive = (items, newIndex) => {
  let newItem
  items.forEach((el, index) => {
    if (index === newIndex) {
      newItem = el.name
        .toLowerCase()
        .replace("&nbsp;", " ")
        .split(" ")
        .join("-")
    }
  })

  return newItem
}
